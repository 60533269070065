import React from 'react'
import Provider, {Context} from './provider'
import styled from 'styled-components'

import responsive from './utils/responsive'

import { GlobalStyle } from './styles/global'

import Header from './components/header'
import CardList from './components/cardList'
import FeaturedSection from './components/featuredSection'
import Playlists from './components/playlists'
import Footer from './components/footer'

const Wrapper = styled.div``

const Container = styled.div`
  transition: .6s ease;
  opacity: ${props => props.isPageLoaded ? '1' : '0'};

  ${props => props.browser !== 'safari' && props.os !== 'iOS' && !props.isAndroid &&`
      width: 100%;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
  `}
`

const ToggleButton = styled.div`
  width: ${responsive(40, 80)};
  height: ${responsive(40, 80)};
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.strokeColor};
  border: 1px solid ${props => props.strokeColor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 3;
  transition: .3s ease;

  @media (max-width: 512px) {
    right: 10px;
    top: 10px;
  }
`

const Dot = styled.div`
  width: ${responsive(14, 28)};
  height: ${responsive(14, 28)};
  background-color: ${props =>props.backgroundColor};
  border-radius: 50%;
  pointer-events: none;
`

const Scroll = styled.div`
  padding-bottom: 20px;

  @media (max-width: 512px) {
    padding-bottom: 0;
  }
`

function App() {
  return (
    <Wrapper>
        <Provider>
          <Context.Consumer>
            {
              (context) => {

                const {
                  color,
                  backgroundColor
                } = context.state.currentTheme

                const {
                  isPageLoaded,
                  browser,
                  os,
                  showCovers
                } = context.state

                return (
                  <Container
                    isPageLoaded={isPageLoaded}
                    browser={browser}
                    os={os}
                    isAndroid={os === 'Android OS'}
                    className='root'
                  >
                    <GlobalStyle
                      color={color}
                      backgroundColor={backgroundColor}
                    />
                    <ToggleButton
                      backgroundColor={showCovers ? color : backgroundColor}
                      strokeColor={color}
                      onClick={() => context.toggleCovers()}
                      data-scroll-target
                      data-scroll-sticky
                      >
                      <Dot backgroundColor={showCovers ? backgroundColor : color}></Dot>
                    </ToggleButton>
                    <Scroll className="scroll asscroll-container">
                      <Header />
                      <CardList />
                      <FeaturedSection />
                      <Playlists />
                      <Footer />
                    </Scroll>
                  </Container>
                )
              }
            }
          </Context.Consumer>
        </Provider>
    </Wrapper>
  )
}

export default App;