import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
            box-sizing: border-box;
  }

@font-face {
    font-family: 'Helvetica LT Pro';
    src: url('./fonts/HelveticaLTPro-Roman.woff2') format('woff2'),
        url('./fonts/HelveticaLTPro-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

  html {
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
  }

  body {
    font-family: 'Helvetica LT Pro';
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    font-size: 16px;
    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    transition: opacity .6s ease;
    overscroll-behavior: none;
  }

  ::selection {
    color: ${props => props.backgroundColor};
    background-color: ${props => props.color};
  }

  a {
    text-decoration: none;
    color: ${props => props.color};
  }

`

export const Grid = styled.div`
  width: 100%;
  padding: ${props => props.noVerticalPadding ? '0 20px' : '20px'};
  display: grid;
  grid-template-columns: ${props => props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(3, 1fr)'};
  grid-gap: 20px;

  @media (max-width: 769px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 512px) {
    grid-template-columns: 1fr;
    padding: ${props => props.noVerticalPadding ? '0 10px' : '10px'};
    grid-gap: 10px;
  }

  ${props => props.isFeaturedSection && `
      @media (max-width: 1100px) {
        grid-template-columns: 1fr;
      }
  `}
`