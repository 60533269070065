import React from 'react'
import styled from 'styled-components'

import responsive from '../utils/responsive'

const Container = styled.div`
  width: 100%;
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 512px) {
    padding: 10px;
  }
`

const Title = styled.h1`
  font-size: ${responsive(75, 164)};
  line-height: 0.79;
  font-weight: 400;
  letter-spacing: -0.05em;
  margin: 0;
  width: 100%;
  flex: 3;
  font-weight: normal;
  transform: translateY(10px);
  
  @media (max-width: 768px) {
    width: 80%;
    align-self: flex-start;
  }
`

const Year = styled.div`
  font-size: ${responsive(75, 164)};
  text-align: right;
  flex: 1;
  line-height: 0.79;
  letter-spacing: -0.05em;
  transform: translateY(10px);
`

const Header = () => {
  return (
    <Container>
      <Title>ALBUM COLORS <br /> OF THE YEAR</Title>
      <Year>2020</Year>
    </Container>
  )
}

export default Header