import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'

import responsive from '../utils/responsive'

import { Context } from '../provider'

const Container = styled.div`
  grid-column: span 3;
  border: 1px solid ${props => props.color};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: ${responsive(14,24)};

  &:after {
    content: "";
    display: block;
    padding-bottom: 88%;
  }

  @media (max-width: 769px) {
    grid-column: span 2;

    &:after {
      content: "";
      padding-bottom: 120%;
    }
  }

  @media (max-width: 512px) {
    grid-column: span 1;
  }
`

const CircleContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Cover = styled.a`
  position: relative;
  width: 60%;
  margin: 0 auto;
  background-color: ${props => props.hexadecimal};
  display: block;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`

const CoverCircle = styled.div`
  position: absolute;
  width: 60%;
  height: 60%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${props => props.heroimageURL});
  transition: opacity .3s ease;
  opacity: ${props => props.showCovers ? '1' : '0'};

  @media (max-width: 769px) {
    width: 60%;
    height: 60%;
  }
`

const CoverContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${props => props.heroimageURL});
  transition: opacity .3s ease, transform .3s ease .3s;
  opacity: ${props => props.showCovers ? '1' : '0'};
  left: 0;
  top: 0;
  pointer-events: none;
`

const TopText = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  text-transform: uppercase;
  width: calc(90% - 40px);
  line-height: 1;

  @media (max-width: 512px) {
    left: 10px;
    top: 10px;
  }
`

const RightContent = styled.div`
  position: absolute;
  right: 20px;
  top: 25%;

  @media (max-width: 512px) {
    width: 120%;
    right: 10px;
    top: 10px;
  }
`

const RightText = styled.a`
  display: block;
  text-transform: uppercase;
  line-height: 1;
  transform-origin: top right;
  transform: rotate(90deg) translate(100%, 0);
`

const BottomText = styled.div`
  width: calc(90% - 40px);
  position: absolute;
  right: 40px;
  bottom: 20px;
  text-transform: uppercase;
  line-height: 1;
  text-align: right;

  @media (max-width: 512px) {
    width: calc(95% - 40px);
    text-align: left;
    bottom: 10px;
    left: 10px;
  }
`

const LeftContent = styled.div`
  position: absolute;
  left: 20px;
  top: 60%;

  @media (max-width: 512px) {
    top: 65%;
    left: 20px;
  }
`

const LeftText = styled.div`
  text-transform: uppercase;
  line-height: 1;
  transform-origin: left center;
  transform: rotate(-90deg);
`

const Hero = () => {

  const context = useContext(Context)
  const [isImageLoaded, showBg] = useState(false)
  

  const {
    currentTheme,
    showCovers,
    currentHeroAlbum,
  } = context.state

  const circleImageUrl = currentHeroAlbum.heroImageURL
  const heroImageURL = currentHeroAlbum.thumbnailURL

  useEffect(() => {
    const image = document.createElement('img')
    image.src = heroImageURL
    image.onload = function () {
      showBg(true)
    }
  })

  return (
    <Container
      color={currentTheme.color}
    >
      <TopText>A COLLECTION OF 150 ALBUM COVERS TO celebrate the colors in music in 2020.</TopText>
      <BottomText>Created and curated by <a href="http://www.gasparian.co/" target='_blank' rel="noreferrer">josé gasparian</a> & <a href="https://marcosrp.co/" target='_blank' rel="noreferrer">marcos rodriguez</a>. Developed by <a href="https://edkf.com.br/" target='_blank' rel="noreferrer">Edgard Kozlowski</a>.</BottomText>
      <LeftContent
        data-scroll
        data-scroll-speed="3.5"
      >
        <LeftText>
          © album colors 2020
        </LeftText>
      </LeftContent>
      <RightContent
        data-scroll
        data-scroll-speed="-2.5"
      >
        <RightText href="/">
          Refresh to change color
        </RightText>
      </RightContent>
      <CircleContainer>
        <CoverCircle
          data-scroll
          data-scroll-speed="0.7"
          showCovers={isImageLoaded && showCovers}
          heroimageURL={circleImageUrl}
        ></CoverCircle>
      </CircleContainer>
      <Cover
        href={currentHeroAlbum.spotifyurl}
        target='_blank'
        rel="noreferrer"
        data-scroll
        data-scroll-speed="1.5"
        hexadecimal={currentHeroAlbum.hexadecimal}
      >
        <CoverContent
          data-scroll
          showCovers={isImageLoaded && showCovers}
          heroimageURL={heroImageURL}
        ></CoverContent>
      </Cover>
    </Container>
  )
}

export default Hero