import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import Clipboard from 'react-clipboard.js'
import { useInView } from 'react-intersection-observer'

import { Grid } from '../styles/global'

import { Context } from '../provider'

import responsive from '../utils/responsive'

const FeaturedCard = styled.div`
  border: 1px solid ${props => props.color};
  padding: 20px 20px 2px 20px;
  font-size: ${responsive(40,90)};
  line-height: 0.9;
  text-transform: uppercase;
  letter-spacing: -0.05em;

  button {
    color: ${props => props.color};
  }

  @media (max-width: 512px) {
    padding: 10px 10px 2px 10px;
  }
`

const Title = styled.div`
  margin-bottom: 20px;

  @media (max-width: 512px) {
    margin-bottom: 5px;
  }
`

const Cover = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: ${props => props.hexadecimal};

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  @media (max-width: 512px) {
    margin-bottom: 10px;
  }
`

const CoverContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: ${props => props.thumbnailurl ? `url(${props.thumbnailurl})` : 'none'};
  transition: opacity .3s ease, transform .3s ease .3s, filter .3s ease .3s;
  opacity: ${props => props.showCovers ? '1' : '0'};
`

const FirstLine = styled.div`
  width: 100%;
`
const SecondLine = styled(Clipboard)`
  color: ${props => props.color};
  width: 100%;
  text-align: right;
  cursor: pointer;
  font-size: ${responsive(40,90)};
  background: none;
  border: none;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  border-radius: 0;
  line-height: 0.8;
  letter-spacing: -0.05em;

  &:focus {
    outline: none;
  }

  &.is-button {

    &:before {
      content: 'Copy ';
      opacity: 0;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    ${
      props => props.isCopied && `
        &:before {
          content: 'Copied ';
          opacity: 1;
        }

      `
    }

    @media (max-width: 512px) {

      margin-bottom: 7px;

      &:before {
        display: none;
      }
    }
  }

`

const FeaturedSection = () => {

  const context = useContext(Context)

  const {
    currentTheme,
    showCovers,
    albums
  } = context.state

  const [isCopied, copyHexadecimal] = useState(false)
  const [isImageLoaded, showBg] = useState(false)
  const albumOfTheYear = albums.find(element => element.album === 'Cenizas')


  const { ref, inView } = useInView({
    /* Optional options */
    // root: document.querySelector('.root'),
    triggerOnce: true,
    threshold: 0,
  })

  useEffect(() => {
    if (inView) {
      const image = document.createElement('img')
      image.src = albumOfTheYear.thumbnailurl
      image.onload = function () {
        showBg(true)
      }
    }

  })

  return (
    albums.length > 0 && (
      <Grid
        columns={2}
        noVerticalPadding
        isFeaturedSection
      >
        <FeaturedCard
          color={currentTheme.color}
        >
          <a
            href={albumOfTheYear.spotifyurl}
            target='_blank'
            rel="noreferrer"
          >
            <Title>Album cover <br /> of the year</Title>
            <Cover
              hexadecimal={albumOfTheYear.hexadecimal}
            >
              <CoverContent
                thumbnailurl={albumOfTheYear.thumbnailurl}
                showCovers={isImageLoaded && showCovers}
                ref={ref}
              >
              </CoverContent>
            </Cover>
            <FirstLine>{albumOfTheYear.artist}</FirstLine>
            <SecondLine>{albumOfTheYear.album}</SecondLine>
          </a>
        </FeaturedCard>
        <FeaturedCard
          color={currentTheme.color}
        >
          <Title>Color <br /> of the year</Title>
          <Cover hexadecimal='#81AEC5'>
            <CoverContent></CoverContent>
          </Cover>
            <FirstLine>blue</FirstLine>
            <SecondLine
              color={currentTheme.color}
              data-clipboard-text='#81AEC5'
              className='is-button'
              onSuccess={() => {
                copyHexadecimal(true)
                setTimeout(() => {
                  copyHexadecimal(false)
                }, 2000)
              }}
              isCopied={isCopied}
            >#81AEC5</SecondLine>
        </FeaturedCard>
      </Grid>
    )
  )
}

export default FeaturedSection