import React from 'react'
import styled from 'styled-components'
import responsive from '../utils/responsive'

const Container = styled.div`
  margin: 20px;
  margin-bottom: 0;
  padding: 20px;
  border: 1px solid ${props => props.color};
  font-size: ${responsive(14,24)};
  line-height: 1;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;

  @media (max-width: 512px) {
    padding: 7px 10px;
    margin: 10px;
  }
`

const Link = styled.a`

  line-height: 0.8;
  transform: translateY(2px);

  @media (max-width: 550px) {
    line-height: 1.6;
  }

`

const Sites = styled.div`
  display: flex;

  div {
    margin-right: 30px;
  }

  @media (max-width: 550px) {
    display: block;

    div {
      margin: 0;
    }
  }
`

const Footer = () => {

  return (
    <Container>
      <Link href='https://www.instagram.com/album_colors/' target='_blank' rel="noreferrer">Instagram</Link>
      <Sites>
        <Link as='div'>album colors 2020</Link>
        <Link href='https://2015.albumcolors.com/' target='_blank' rel="noreferrer">album colors 2015</Link>

      </Sites>
    </Container>
  )
}

export default Footer