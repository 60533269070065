import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import Clipboard from 'react-clipboard.js'
import { useInView } from 'react-intersection-observer'

import responsive from '../utils/responsive'

import { Context } from '../provider'

const Container = styled.div`
  width: 100%;
  text-decoration: none;
  color: ${props => props.color};
  display: block;
  border: 1px solid ${props => props.color};
  padding: 20px;
  text-transform: uppercase;
  font-size: ${responsive(14,24)};
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.--bigger {
    grid-column: span 2;
    grid-row: span 2;
  }

  @media (max-width: 768px) {
    &.--bigger {
      grid-column: span 1;
      grid-row: span 1;
    }
  }

  @media (max-width: 512px) {
    padding: 10px;
  }
`

const Cover = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: ${props => props.hexadecimal};

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  @media (max-width: 512px) {
    margin-bottom: 10px;
  }
`

const CoverContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${props => props.thumbnailURL});
  transition: opacity .3s ease, transform .3s ease .3s, filter .3s ease .3s;
  opacity: ${props => props.showCovers ? '1' : '0'};
`

const AlbumData = styled.a`
  margin-bottom: 20px;

  @media (max-width: 512px) {
    margin-bottom: 10px;
  }
`

const Artist = styled.div`
  width: 100%;
`
const Album = styled.div`
  width: 100%;
`
const Hexadecimal = styled(Clipboard)`
  font-family: 'Helvetica';
  color: ${props => props.color};
  font-weight: normal;
  width: 100%;
  text-align: right;
  align-self: end;
  cursor: pointer;
  font-size: ${responsive(14,24)};
  background: none;
  border: none;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  border-radius: 0;
  transform: translateY(3px);

  &:before {
    content: 'Copy ';
    opacity: 0;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  ${
    props => props.isCopied && `
      &:before {
        content: 'Copied ';
        opacity: 1;
      }

    `
  }

  &:focus {
    outline: none;
  }
`

const Card = (props) => {

  const [isCopied, copyHexadecimal] = useState(false)
  const [isImageLoaded, showBg] = useState(false)

  const { ref, inView } = useInView({
    /* Optional options */
    // root: document.querySelector('.root'),
    triggerOnce: true,
    threshold: 0,
  })

  const context = useContext(Context)

  const {
    artist,
    album,
    thumbnailURL,
    size,
    hexadecimal,
    spotifyurl
  } = props.data

  const {
    currentTheme,
    showCovers,
    browserSupportsWebp
  } = context.state

  useEffect(() => {
    if (inView) {
      const image = document.createElement('img')
      image.src = thumbnailURL
      image.onload = function () {
        showBg(true)
      }
    }

  })

  return (
    <Container
      color={currentTheme.color}
      className={size === 'big' ? '--bigger' : false}
      ref={ref}
    >
        <AlbumData
          href={spotifyurl}
          target='_blank'
          rel="noreferrer"
        >
          <Cover
            hexadecimal={hexadecimal}
          >
          {
            inView && <CoverContent thumbnailURL={(browserSupportsWebp && size !== 'big') ? thumbnailURL.replace('.png', '.webp') : thumbnailURL} showCovers={isImageLoaded && showCovers} > </CoverContent>
          }
          </Cover>
          <Artist>{artist}</Artist>
          <Album>{album}</Album>
        </AlbumData>
        <Hexadecimal
          color={currentTheme.color}
          data-clipboard-text={hexadecimal}
          onSuccess={() => {
            copyHexadecimal(true)
            setTimeout(() => {
              copyHexadecimal(false)
            }, 2000)
          }}
          isCopied={isCopied}
        >
          {!isCopied && hexadecimal}
        </Hexadecimal>
    </Container>
  )
}

export default Card