import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { Context } from '../provider'
import responsive from '../utils/responsive'

import coverSpotify from '../images/cover-spotify.png'
import coverAppleSrc from '../images/cover-apple-music.png'

const Container = styled.div`
  margin: 20px;
  margin-bottom: 0;
  padding: 20px;
  border: 1px solid ${props => props.color};
  font-size: ${responsive(40,90)};
  line-height: 0.9;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  letter-spacing: -0.05em;

  @media (max-width: 1100px) {
    display: block;
  }

  @media (max-width: 512px) {
    margin: 10px;
    padding: 10px;
  }
`

const Title = styled.div`
  transform: translateY(6px);

  @media (max-width: 1100px) {
    margin-bottom: 20px;
  }

  @media (max-width: 512px) {
    margin-bottom: 10px;
  }
`

const PlaylistLinks = styled.div`
  display: flex;

  @media (max-width: 512px) {
    display: block;
  }
`

const PlaylistLink = styled.a`
  position: relative;
  width: ${responsive(170,243, 1110, 1920)};
  display: block;
  margin-left: 20px;
  background-color: ${props => props.hexadecimal};

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  @media (max-width: 1100px) {
    margin: 0;
    width: 100%;

    &:first-child {
      margin-right: 20px;
    }
  
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  @media (max-width: 512px) {

    &:first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`

const PlaylistCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${props => props.thumbnailurl});
  transition: opacity .3s ease, transform .3s ease .3s, filter .3s ease .3s;
  opacity: ${props => props.showCovers ? '1' : '0'};
`

const BrDesktop = styled.br`
  @media (max-width: 1100px) {
    display: none;
  }
`

const BrMobile = styled.br`
  @media (min-width: 1100px) {
    display: none;
  }
`

const Playlists = () => {

  const context = useContext(Context)

  const {
    currentTheme,
    showCovers,
  } = context.state

  const [isImageLoaded, showBg] = useState(false)

  const { ref, inView } = useInView({
    /* Optional options */
    // root: document.querySelector('.root'),
    triggerOnce: true,
    threshold: 0,
  })

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        showBg(true)
      }, 1000)
    }

  })

  return (
    <Container
      color={currentTheme.color}
      ref={ref}
    >
      <Title>LIsten to <BrDesktop /> the <BrMobile/> colors <BrDesktop /> of 2020</Title>
      <PlaylistLinks
      >
        <PlaylistLink
          href='https://open.spotify.com/playlist/7jTJHEYZi7IZYFNRrc9mRL?si=sCFLWt6sREuFDsofhOWyDA'
          target='_blank'
          rel="noreferrer"
          hexadecimal='#99AA91'
        >
          <PlaylistCover showCovers={isImageLoaded && showCovers} thumbnailurl={coverSpotify} />
        </PlaylistLink>
        <PlaylistLink
          href='https://music.apple.com/us/playlist/album-colors-of-the-year-2020/pl.u-PDb4Nd6TLYpbl5'
          target='_blank'
          rel="noreferrer"
          hexadecimal='#FECCC0'
        >
          <PlaylistCover showCovers={isImageLoaded && showCovers} thumbnailurl={coverAppleSrc} />
        </PlaylistLink>
      </PlaylistLinks>
    </Container>
  )
}

export default Playlists