const themes = [
  {
    name: 'green',
    color: '#35361E',
    backgroundColor: '#99AA91'
  },
  {
    name: 'blue',
    color: '#B2B8CD',
    backgroundColor: '#5D83A7'
  },
  {
    name: 'beige',
    color: '#2E251A',
    backgroundColor: '#E2B593'
  },
  {
    name: 'red',
    color: '#B40B11',
    backgroundColor: '#FECCC0'
  },
  {
    name: 'yellow',
    color: '#503D33',
    backgroundColor: '#D59B4A'
  },
  {
    name: 'orange',
    color: '#4F503E',
    backgroundColor: '#DD8434'
  },
  {
    name: 'pink',
    color: '#1B41C8',
    backgroundColor: '#F881FB'
  },
  {
    name: 'black',
    color: '#FFFFFF',
    backgroundColor: '#000000'
  },
  {
    name: 'gray',
    color: '#646464',
    backgroundColor: '#DCDCDC'
  },
  {
    name: 'white',
    color: '#000000',
    backgroundColor: '#FFFFFF'
  },
]

export default themes