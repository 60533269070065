import React, { useContext } from 'react'
import { Context } from '../provider'

import { Grid } from '../styles/global'

import Card from './card'
import Hero from './hero'

const Cardlist = () => {

  const context = useContext(Context)

  const {
    albums,
    currentHeroAlbum
  } = context.state

  return (
    <Grid>
      {
        currentHeroAlbum && <Hero />
      }
      {
        albums.map((item, index) => (
          <Card data={item} key={index}/>
        ))
      }
    </Grid>
  )
}

export default Cardlist