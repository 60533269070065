import React, { Component } from 'react'
import locomotiveScroll from "locomotive-scroll"
import { detect } from 'detect-browser'
import supportsWebP from 'supports-webp'

import getAlbums from './data/albums'
import themes from './data/themes'

import ASScroll from '@ashthornton/asscroll'

export const Context = React.createContext()

class Provider extends Component {

  constructor(props) {
    super(props)
    this.toggleCovers = this.toggleCovers.bind(this)

    this.state = {
      albums: [],
      isPageLoaded: false,
      showCovers: true,
      currentTheme: themes[Math.floor(Math.random() * themes.length)],
      currentHeroAlbum: null,
      scrollPosY: 0,
      browser: detect().name,
      os: detect().os,
      browserSupportsWebp: false
    }
  }

  componentDidMount () {

    const scrollEl = document.querySelector('.scroll')

    supportsWebP.then(supported => {
      if (supported) {
        this.setState({
          browserSupportsWebp: true
        })
      }
    })

    getAlbums()
      fetch('./albumList.json')
      .then((response) => response.json())
      .then( albums => {
        const getCurrentThemeAlbums = albums.filter((item, index) => item.categoryColor === this.state.currentTheme.name && item.heroImageURL !== '')
        console.log(getCurrentThemeAlbums)
        const currentHeroAlbum = getCurrentThemeAlbums[Math.floor(Math.random() * getCurrentThemeAlbums.length)]
        console.log(currentHeroAlbum)

        this.setState({
          albums,
          currentHeroAlbum
        })

        new locomotiveScroll({
          el: scrollEl,
          smooth: true
        })

        if (this.state.browser === 'safari') {
          const smoothScroll = new ASScroll()

          smoothScroll.enable()

          window.addEventListener('resize', () => {
            document.location.reload(true)
          })
        }
        
        setTimeout(() => {
          this.setState({
            isPageLoaded: true
          })
        }, 500)
      })


  }

  toggleCovers () {
    this.setState(prevState => ({
      showCovers: !prevState.showCovers
    }))
  }

  render () {

    const {
      state,
    } = this

    return (
      <Context.Provider
        value={{
          state,
          toggleCovers: () => this.toggleCovers()
        }}
      >
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default Provider